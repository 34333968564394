// Use alpine for menu
import Alpine from 'alpinejs';

Alpine.start();

/* Toggle header colours */
const header = document.getElementById('header');
const logo = document.getElementById('header-logo-normal');
const whiteLogo = document.getElementById('header-logo-white');

document.addEventListener('scroll', function () {
  /* Apply classes for slide in bar */
  if (window.scrollY > 10) {
    header.classList.add('bg-white');
    header.classList.add('text-black');
    header.classList.remove('text-white');
    header.classList.remove('gradient');
    header.classList.add('shadow');
    logo.classList.remove('hidden');
    whiteLogo.classList.add('hidden');
  } else {
    header.classList.remove('bg-white');
    header.classList.remove('text-black');
    header.classList.add('text-white');
    header.classList.add('gradient');
    header.classList.remove('shadow');
    logo.classList.add('hidden');
    whiteLogo.classList.remove('hidden');
  }
});

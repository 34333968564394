import * as Sentry from '@sentry/browser';

// Only bind when DSN is available
if (window.SENTRY_DSN) {
  // Create the default sentry client
  // This instance will communicate any default JS errors, as long as the Vue integration does not override it
  // Vue must always be loaded after this default app.ts!
  Sentry.init({
    dsn: window.SENTRY_DSN,
    release: window.SENTRY_RELEASE,
  });

  Sentry.setUser({
    ip_address: window.SENTRY_IP_ADDRESS,
  });
}
